import React, { useState, useEffect } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { trackChangesEnableIcon, checkIcon } from '@progress/kendo-svg-icons';
import { Controller, UseFormReturn } from 'react-hook-form';
import { SvgIcon } from '@progress/kendo-react-common';
import { chevronLeftIcon } from '@progress/kendo-svg-icons';
import { Input, NumericInput, SubmitButton } from '../../../../components/form';
import { BandInfo, ViewLayer } from '../../../../types';
import { useViewLayerParamsUpdate } from '../../../../hooks/viewerconfig';
import { Loader } from '@progress/kendo-react-indicators';
import { updateLayerParam } from '../../../../common/viewerConfigHelper';
import { Checkbox } from '@progress/kendo-react-inputs';

interface WFSPropertiesProps {
  layer: ViewLayer;
  form: UseFormReturn<any>;
}

const WFSProperties: React.FC<WFSPropertiesProps> = (props: WFSPropertiesProps) => {
  const [layer, setLayer] = React.useState(null);
  const [hovering, setHovering] = React.useState(null);
  const [modifying, setModifying] = React.useState(null);
  const [modifyingValue, setModifyingValue] = React.useState(null);
  const viewLayerParamsUpdateMutation = useViewLayerParamsUpdate();

  useEffect(() => {
    setModifying(null);
    setModifyingValue(null);
    setHovering(null);
    setLayer(props.layer);
  }, [props.layer]);

  const handleModify = (propertyName: string) => {
    const newViewLayer = JSON.parse(JSON.stringify(layer));
    let newValue: string = modifyingValue;
    if (propertyName === 'uri') {
      if (newValue.endsWith('/wfs')) {
        newValue = newValue.slice(0, -4); // Removing last 4 characters ('/wfs')
      }
    }
    if (propertyName === 'wmsStyle') {
      if (newValue.toLowerCase() === 'null') {
        newValue = null;
      }
    }
    updateLayerParam(newViewLayer, propertyName, newValue);
    viewLayerParamsUpdateMutation
      .mutateAsync({ viewLayerId: newViewLayer.id, params: newViewLayer.params })
      .then(() => {
        setHovering(null);
        setModifying(null);
        setModifyingValue(null);
        setLayer(newViewLayer);
      });
  };

  const handleToggleCheckValue = (propertyName: string) => {
    const newViewLayer = JSON.parse(JSON.stringify(layer));
    updateLayerParam(newViewLayer, propertyName, !layer.paramsMap[propertyName]);
    viewLayerParamsUpdateMutation
      .mutateAsync({ viewLayerId: newViewLayer.id, params: newViewLayer.params })
      .then(() => {
        setHovering(null);
        setModifying(null);
        setModifyingValue(null);
        setLayer(newViewLayer);
      });
  };

  const renderPropertyData = (
    title: string,
    propertyName: string,
    value: string | number | JSX.Element,
    modifiable: boolean
  ) => {
    const modifyingThisProperty = modifying === propertyName;
    return (
      <div
        style={{ display: 'flex', position: 'relative', alignItems: modifyingThisProperty ? 'center' : 'flex-start' }}
        onMouseEnter={() => {
          if (!modifying && modifiable) setHovering(propertyName);
        }}
        onMouseLeave={() => {
          setHovering(null);
        }}
      >
        <span style={{ flex: '1' }}>{title}</span>
        <div style={{ display: 'flex', alignItems: 'center', flex: '4' }}>
          {!modifyingThisProperty && value}
          {modifyingThisProperty && (
            <Input
              className={'w-100 me-4'}
              style={{ padding: '0.25rem !important' }}
              type="text"
              name={propertyName}
              value={modifyingValue}
              onChange={(e) => {
                setModifyingValue(e.value);
              }}
            />
          )}
        </div>
        {hovering === propertyName && (
          <SvgIcon
            icon={trackChangesEnableIcon}
            style={{
              color: 'black',
              fontSize: '1.2rem',
              cursor: 'pointer',
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              margin: 'auto',
            }}
            onClick={() => {
              setModifying(propertyName);
              setModifyingValue(value);
              setHovering(null);
            }}
          />
        )}
        {modifyingThisProperty && viewLayerParamsUpdateMutation.isLoading && (
          <Loader
            style={{
              color: 'black',
              fontSize: '1.2rem',
              cursor: 'pointer',
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              margin: 'auto',
            }}
          />
        )}
        {modifyingThisProperty && !viewLayerParamsUpdateMutation.isLoading && (
          <SvgIcon
            icon={checkIcon}
            style={{
              color: 'black',
              fontSize: '1.2rem',
              cursor: 'pointer',
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              margin: 'auto',
            }}
            onClick={() => {
              handleModify(propertyName);
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography.h3>WFS Properties</Typography.h3>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {renderPropertyData('Uri', 'uri', layer?.paramsMap['uri'] ? layer?.paramsMap['uri'] + '/wfs' : 'N/A', true)}
        {renderPropertyData(
          'Workspace',
          'workspace',
          layer?.paramsMap['workspace'] ? layer?.paramsMap['workspace'] : 'N/A',
          true
        )}
        {renderPropertyData('Layer', 'layer', layer?.paramsMap['layer'] ? layer?.paramsMap['layer'] : 'N/A', true)}
        <div style={{ display: 'flex' }}>
          <span style={{ flex: '1' }}>Projection:</span>
          <span style={{ flex: '4' }}>{layer?.paramsMap['projection'] ? layer?.paramsMap['projection'] : 'N/A'}</span>
        </div>
        {renderPropertyData(
          'Extents',
          'extents',
          layer?.paramsMap['extents'] && (
            <div style={{ flex: '4', display: 'flex', flexDirection: 'column' }}>
              <span>{`${layer?.paramsMap['extents'][0]}, ${layer?.paramsMap['extents'][1]}`}</span>
              <span>{`${layer?.paramsMap['extents'][2]}, ${layer?.paramsMap['extents'][3]}`}</span>
            </div>
          ),
          false
        )}
        {renderPropertyData(
          'WMS Style',
          'wmsStyle',
          layer?.paramsMap['wmsStyle'] ? layer?.paramsMap['wmsStyle'] : 'N/A',
          true
        )}
        {renderPropertyData('Z Index', 'zIndex', layer?.paramsMap['zIndex'] ? layer?.paramsMap['zIndex'] : '1', true)}

        <div style={{ display: 'flex' }}>
          <label>Show Legend: </label>
          <Checkbox
            style={{ marginLeft: '0.75rem' }}
            checked={layer?.paramsMap['showLegend']}
            onChange={(e) => {
              handleToggleCheckValue('showLegend');
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default WFSProperties;
