import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { Skeleton } from '@progress/kendo-react-indicators';
import { SapFlowTransaction } from '../../types';
import { useTransaction, useTransactionFileDetails } from '../../hooks/sapflow';
import { LinkButton } from '../../components/layout';
import Summary from './Summary';
import Files from './Files';
import Logs from './Logs';

const SapFlowDetails: React.FC = () => {
  const [sapFlowInfo, setSapFlowInfo] = useState<SapFlowTransaction>(null);
  const { transactionId } = useParams();
  const transactionQuery = useTransaction(transactionId);
  //const sapflowDetailsQuery = useSapFlowDetails(transactionId);
  const { getFileDetails } = useTransactionFileDetails();
  const fileDetailsQuery = getFileDetails(transactionId);

  useEffect(() => {
    if (transactionQuery.isSuccess) {
      setSapFlowInfo(transactionQuery.data);
    }
  }, [transactionQuery.isSuccess]);

  return (
    <div className="container-fluid p-2 p-lg-5">
      <div className="row">
        <div className="col pb-5">
          <div className="row gx-3 gy-2">
            <div className="col col-sm-auto d-flex justify-content-center">
              <LinkButton label="Run SAPFlow" to={`/sapflow/123`} />
            </div>
            <div className="col col-sm-auto d-flex justify-content-center">
              <LinkButton label="New Project" to="/sapflow/new" />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Typography.h3 className="text-uppercase">
            {transactionQuery.isLoading && <Skeleton shape="text" />}
            {transactionQuery.isSuccess && sapFlowInfo && (
              <>
                <Link to="/dashboard" style={{ color: 'var(--geosap-body-text)' }}>
                  Projects
                </Link>
                {' > '}
                <Link to={`/project/${sapFlowInfo.project.id}`} style={{ color: 'var(--geosap-body-text)' }}>
                  {sapFlowInfo.project.name}
                </Link>
                {' > '}
                <span style={{ color: 'var(--geosap-k-button-md)' }}>{sapFlowInfo.sapflowname}</span>
              </>
            )}
          </Typography.h3>
        </div>
      </div>
      <div className="row pt-5 gx-5">
        <div className="col-12 col-lg-4">
          <div className="row gy-4">
            <div className="col-12">
              <Typography.h4>Sap Flow Details</Typography.h4>
              {(transactionQuery.isLoading || fileDetailsQuery.isLoading) && (
                <Skeleton shape="rectangle" className="w-100" style={{ height: '600px' }} />
              )}
              {transactionQuery.isSuccess && fileDetailsQuery.isSuccess && sapFlowInfo && (
                <Summary sapFlowInfos={sapFlowInfo} fileDetails={fileDetailsQuery.data} />
              )}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8">
          <Files
            isLoading={transactionQuery.isLoading || fileDetailsQuery.isLoading}
            isSuccess={transactionQuery.isSuccess && fileDetailsQuery.isSuccess}
            inputFileData={fileDetailsQuery.data?.input}
            outputFileData={fileDetailsQuery.data?.output}
          />
          <Logs statusHistory={sapFlowInfo?.transactionHistory} />
        </div>
      </div>
    </div>
  );
};

export default SapFlowDetails;
