import React, { useEffect, useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import { Loader } from '@progress/kendo-react-indicators';
import { Feature, MapBrowserEvent } from 'ol';
import 'ol/ol.css';
import MapEvent from 'ol/MapEvent';
import OLMap from 'ol/Map';
import { fromLonLat } from 'ol/proj';
import GeoJSON from 'ol/format/GeoJSON.js';
import TileWMS from 'ol/source/TileWMS.js';
import VectorSource from 'ol/source/Vector.js';
import { bbox as bboxStrategy } from 'ol/loadingstrategy.js';
import throttle from 'lodash.throttle';
import debounce from 'lodash.debounce';
import { threeToLeafletPosition } from '../../../converters/threeToLeafletPosition';
import {
  Layer2DClickResult,
  LayerType,
  NavigationMode2D,
  SapFlowViewConfig,
  ViewLayer,
  isZeroVector,
} from '../../../types';
import Map from './Map';
import {
  Layers,
  TileLayer,
  VectorLayer,
  GeotiffLayer,
  ShapefileLayer,
  WfsPreviewLayer,
  WmsPreviewLayer,
  BaseMapLayer,
  GsRasLayer,
  GsVecLayer,
} from './Layers';
import { VCMeasurementTool, VCDrawingTool } from './Controls/';
import {
  Controls,
  FullScreenControl,
  MouseTrackerControl,
  PixelInfoControlV2 as PixelInfoControl,
  ScaleLineControl,
} from './Controls';
import { fetchProj4 } from '../../../common/proj4Helper';
import OLMenuBar from './OLMenuBar';
import { useConsumeViewerState } from '../../../context/viewer';
import { updateViewConfigParam } from '../../../common/viewerConfigHelper';

declare const window: any;
declare const proj4: any;

const THREE = window.THREE;

interface Props {
  show?: boolean;
  onLayerUpdated?: (layer: ViewLayer, persistent: boolean) => void;
  onViewConfigParamsUpdate?: (viewConfig: SapFlowViewConfig, persistent: boolean, immediate: boolean) => void;
  onSaveView?: () => void;
}

type Ref = {
  updateCamera: (threeScene: any) => void;
  zoomToLayer: (layer: any) => void;
} | null;
const OLViewer = forwardRef<Ref, Props>((props, ref) => {
  const {
    dispatch,
    viewConfig,
    layers,
    olMap,
    hoveredFeature,
    viewerHeight,
    olMapInitialized,
    cameraPosition2D,
    zoomLevel2D,
    mode2d,
  } = useConsumeViewerState();
  const [positionInitialized, setPositionInitialized] = useState<boolean>(false);
  const [loadingMapData, setLoadingMapData] = useState<boolean>(false);
  const [firstPositionLayer, setFirstPositionLayer] = useState<ViewLayer>(null);
  const [baseMapLayer, setBaseMapLayer] = React.useState<ViewLayer>(null);
  const [geotifLayers, setGeotifLayers] = React.useState([]);
  const [gsRasLayers, setGSRASLayers] = React.useState([]);
  const [gsVecLayers, setGSVECLayers] = React.useState([]);
  const [wmsLayers, setWMSLayers] = React.useState([]);
  const [wfsLayers, setWFSLayers] = React.useState([]);
  const [shapefileLayers, setShapefileLayers] = React.useState([]);
  const hoveredFeatureRef = React.useRef<any>({});
  const olMapRef = React.useRef<any>(olMap);
  const layerRefs = React.useRef<any>({});
  const measurementsRefs = React.useRef<any>(null);
  const drawingsRefs = React.useRef<any>(null);
  const mode2dRef = React.useRef<any>(mode2d);
  mode2dRef.current = mode2d;

  hoveredFeatureRef.current = hoveredFeature;
  olMapRef.current = olMap;

  // INITIAL POSITIONING
  useEffect(() => {
    if (olMapInitialized && !positionInitialized && firstPositionLayer) {
      setTimeout(() => {
        if (cameraPosition2D && cameraPosition2D[0] !== 0 && cameraPosition2D[0] !== 0) {
          // Do nothing,
        } else {
          if (layerRefs.current[firstPositionLayer.id]) {
            // TODO: This is a hack to get the layer to zoom to the correct position. Timeout is not ideal.
            setTimeout(() => {
              layerRefs.current[firstPositionLayer.id]?.zoomToLayer();
              setPositionInitialized(true);
            }, 1000);
          }
        }
      }, 500);
    }
  }, [olMapInitialized, firstPositionLayer]);

  useEffect(() => {
    if (layers) {
      const loadLayers = async () => {
        let firstLayer: ViewLayer = null;
        const newGeotifLayers: any[] = []; //JSON.parse(JSON.stringify(geotifLayers));
        const newWMSLayers: any[] = []; //JSON.parse(JSON.stringify(wmsLayers));
        const newGSRASLayers: any[] = []; //JSON.parse(JSON.stringify(gsRasLayers));
        const newGSVECLayers: any[] = []; //JSON.parse(JSON.stringify(gsVecLayers));
        const newWFSLayers: any[] = []; //JSON.parse(JSON.stringify(wfsLayers));
        const newShapeFileLayers: any[] = []; //JSON.parse(JSON.stringify(shapefileLayers));
        for (let it = 0; it < layers.length; it++) {
          const layer = layers[it];
          let i = 0;
          let layerFound = false;

          if (layer.paramsMap?.projection) {
            const code = layer.paramsMap['projection'];
            await fetchProj4(code);
          }
          if (layer.layerType === LayerType.BaseMap) {
            setBaseMapLayer(layer);
          }
          if (layer.layerType === LayerType.GeoTif) {
            for (i = 0; i < newGeotifLayers.length; i++) {
              if (newGeotifLayers[i].id === layer.id) {
                newGeotifLayers[i] = layer;
                layerFound = true;
              }
            }
            if (!layerFound) {
              newGeotifLayers.push(layer);
            }
            if (firstLayer === null && layer.active) {
              firstLayer = layer;
            }
          }
          if (layer.layerType === LayerType.WMS) {
            for (i = 0; i < newWMSLayers.length; i++) {
              if (newWMSLayers[i].id === layer.id) {
                newWMSLayers[i] = layer;
                layerFound = true;
              }
            }
            if (!layerFound) {
              newWMSLayers.push(layer);
            }
            if (firstLayer === null && layer.active) {
              firstLayer = layer;
            }
          }
          if (layer.layerType === LayerType.WFS) {
            for (i = 0; i < newWFSLayers.length; i++) {
              if (newWFSLayers[i].id === layer.id) {
                newWFSLayers[i] = layer;
                layerFound = true;
              }
            }
            if (!layerFound) {
              newWFSLayers.push(layer);
              if (layer.paramsMap['projection']) {
                const code = layer.paramsMap['projection'];
                fetchProj4(code);
              }
            }
            if (firstLayer === null && layer.active) {
              firstLayer = layer;
            }
          }
          if (layer.layerType === LayerType.ShapeFile || layer.layerType === LayerType.GeoJSON) {
            for (i = 0; i < newShapeFileLayers.length; i++) {
              if (newShapeFileLayers[i].id === layer.id) {
                newShapeFileLayers[i] = layer;
                layerFound = true;
              }
            }
            if (!layerFound) {
              newShapeFileLayers.push(layer);
            }
            if (firstLayer === null && layer.active) {
              firstLayer = layer;
            }
          }
          if (layer.layerType === LayerType.GSRAS) {
            for (i = 0; i < newGSRASLayers.length; i++) {
              if (newGSRASLayers[i].id === layer.id) {
                newGSRASLayers[i] = layer;
                layerFound = true;
              }
            }
            if (!layerFound) {
              newGSRASLayers.push(layer);
            }
            if (firstLayer === null && layer.active) {
              firstLayer = layer;
            }
          }
          if (layer.layerType === LayerType.GSVEC) {
            for (i = 0; i < newGSVECLayers.length; i++) {
              if (newGSVECLayers[i].id === layer.id) {
                newGSVECLayers[i] = layer;
                layerFound = true;
              }
            }
            if (!layerFound) {
              newGSVECLayers.push(layer);
            }
            if (firstLayer === null && layer.active) {
              firstLayer = layer;
            }
          }
        }
        setGeotifLayers(newGeotifLayers);
        setGSRASLayers(newGSRASLayers);
        setGSVECLayers(newGSVECLayers);
        setWMSLayers(newWMSLayers);
        setWFSLayers(newWFSLayers);
        setShapefileLayers(newShapeFileLayers);
        if (firstLayer !== null) {
          setFirstPositionLayer(firstLayer);
        }
      };
      loadLayers();
    }
  }, [layers]);

  const updateCamera = (threeScene: any) => {
    if (!positionInitialized) {
      const camera = threeScene.getActiveCamera();

      const pPos = new THREE.Vector3(0, 0, 0).applyMatrix4(camera.matrixWorld);

      const cPos = threeToLeafletPosition(pPos);
      const newPosArr = fromLonLat([cPos[0], cPos[1]]);
      dispatch({
        type: 'OL_CAMERA_MOVED',
        payload: { position: fromLonLat([cPos[0], cPos[1]]), zoomLevel: zoomLevel2D },
      });
      setPositionInitialized(true);
    }
  };

  const handleCameraMoved = (newPos: any, zoomLevel: number) => {
    dispatch({ type: 'OL_CAMERA_MOVED', payload: { position: newPos, zoomLevel } });
  };

  const pointerMovedHandler = (event: MapEvent, map: OLMap, mapTarget: HTMLElement) => {
    const pixel = map.getEventPixel((event as any).originalEvent);
    let layerFeatureFound: { layerId: string; featureId: string | number } = null;

    map.forEachFeatureAtPixel(pixel, function (f: Feature) {
      const hoveredFeatureInfos = { layerId: f.getProperties()['layerId'], featureId: f.getId() };
      if (!layerFeatureFound && hoveredFeatureInfos.layerId && hoveredFeatureInfos.featureId) {
        if (f.getProperties()['interactable']) {
          layerFeatureFound = hoveredFeatureInfos;
        }
      }
    });

    if (layerFeatureFound) {
      if (
        layerFeatureFound.layerId !== hoveredFeatureRef.current?.layerId ||
        layerFeatureFound.featureId !== hoveredFeatureRef.current?.featureId
      ) {
        mapTarget.style.cursor = 'pointer';
        dispatch({ type: 'FEATURE_ENTER', payload: layerFeatureFound });
      }
    } else if (hoveredFeatureRef.current) {
      mapTarget.style.cursor = '';
      dispatch({ type: 'FEATURE_CLEAR' });
    }
  };

  const throttledPointerMovedHandler = throttle(pointerMovedHandler, 100);

  const handleMapClick = async (evt: MapBrowserEvent<any>) => {
    if (mode2dRef.current !== NavigationMode2D.NORMAL) return;
    let clickResult: Layer2DClickResult;
    if (!layerRefs.current || layerRefs.current.length === 0) {
      return;
    }
    const layerRefIds = Object.keys(layerRefs.current);
    for (let i = 0; i < layerRefIds.length; i++) {
      if (layerRefs.current[layerRefIds[i]] && layerRefs.current[layerRefIds[i]].handleMapClick) {
        clickResult = await layerRefs.current[layerRefIds[i]].handleMapClick(evt);
        if (clickResult) {
          break;
        }
      }
    }
    if (!clickResult && measurementsRefs.current) {
      clickResult = await measurementsRefs.current.handleMapClick(evt);
    }

    if (clickResult) {
      if (clickResult.layer) {
        dispatch({ type: 'SELECT_LAYER', payload: { layer: clickResult.layer } });
      }
      if (clickResult.features && clickResult.features.length > 0) {
        dispatch({
          type: 'SELECT_FEATURE',
          payload: {
            layerId: clickResult.features[0].getProperties()['layerId'],
            feature: clickResult.features[0],
          },
        });
      } else {
        dispatch({ type: 'SELECT_FEATURE', payload: null });
      }
    } else {
      dispatch({ type: 'SELECT_LAYER', payload: { layer: null } });
      dispatch({ type: 'SELECT_FEATURE', payload: null });
    }
  };

  const debouncedMapClick = useCallback(debounce(handleMapClick, 50), []);

  const handleMapEvent = (event: MapEvent, map: OLMap, mapTarget: HTMLElement) => {
    if (event.type === 'postrender') {
      if (!olMapRef.current) {
        dispatch({ type: 'OL_INIT', payload: { map: map } });
      }
    } else if (event.type === 'loadstart') {
      setLoadingMapData(true);
    } else if (event.type === 'loadend') {
      setLoadingMapData(false);
    } else if (event.type === 'pointermove') {
      throttledPointerMovedHandler(event, map, mapTarget);
    } else if (event.type === 'singleclick') {
      debouncedMapClick(event as MapBrowserEvent<any>);
    }
  };

  const zoomToLayer = (layer: any) => {
    if (layerRefs.current[layer.id]) {
      layerRefs.current[layer.id].zoomToLayer(layer);
    } else if (layer.layerType === LayerType.Measurement2D && measurementsRefs.current) {
      measurementsRefs.current.zoomToLayer(layer);
    }
  };

  useImperativeHandle(ref, () => ({
    updateCamera,
    zoomToLayer,
  }));

  const handleLayerUpdate = (layer: ViewLayer) => {
    props.onLayerUpdated(layer, false);
  };

  const usePreviewMode = true;

  return (
    <div
      id="olContainer"
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: viewerHeight + 'px',
        backgroundColor: 'green',
        opacity: props.show ? 1 : 0,
        zIndex: props.show ? 1 : -1,
      }}
    >
      <OLMenuBar onLayerUpdated={handleLayerUpdate} onSaveView={props.onSaveView}></OLMenuBar>
      <Map
        center={cameraPosition2D}
        zoom={zoomLevel2D}
        handleCameraMoved={handleCameraMoved}
        handleMapEvent={handleMapEvent}
      >
        <Layers>
          <BaseMapLayer zIndex={0} show={baseMapLayer && baseMapLayer.active}></BaseMapLayer>

          {/* <TileLayer
            show={true}
            zIndex={2}
            source={
              new TileWMS({
                url: 'https://ahocevar.com/geoserver/wms',
                params: { LAYERS: 'topp:states', TILED: true },
                serverType: 'geoserver',
                // Countries have transparency, so do not fade tiles:
                transition: 0,
              })
            }
          ></TileLayer> */}

          {geotifLayers.map((layer: ViewLayer, index) => {
            return (
              <GeotiffLayer
                ref={(el: any) => {
                  layerRefs.current[layer.id] = el;
                }}
                key={layer.id}
                show={layer.active}
                layer={layer}
                onLayerUpdated={handleLayerUpdate}
                zIndex={geotifLayers.length - index}
              ></GeotiffLayer>
            );
          })}
          {wmsLayers.map((layer: ViewLayer) => {
            const url = layer.paramsMap['uri'] + '/wms';
            const layerName =
              (layer.paramsMap['workspace'] ? layer.paramsMap['workspace'] + ':' : '') + layer.paramsMap['layer'];
            return (
              <WmsPreviewLayer
                ref={(el: any) => {
                  layerRefs.current[layer.id] = el;
                }}
                key={layer.id}
                layer={layer}
                show={layer.active}
                zIndex={1}
              ></WmsPreviewLayer>
            );
          })}
          {wfsLayers.map((layer: ViewLayer) => {
            const url = layer.paramsMap['uri'];
            const layerName =
              (layer.paramsMap['workspace'] ? layer.paramsMap['workspace'] + ':' : '') + layer.paramsMap['layer'];
            if (usePreviewMode) {
              return (
                <WfsPreviewLayer
                  ref={(el: any) => {
                    layerRefs.current[layer.id] = el;
                  }}
                  key={layer.id}
                  show={layer.active}
                  layer={layer}
                ></WfsPreviewLayer>
              );
            } else {
              return (
                <VectorLayer
                  ref={(el: any) => {
                    layerRefs.current[layer.id] = el;
                  }}
                  key={layer.id}
                  show={layer.active}
                  layer={layer}
                  zIndex={1}
                  style={{
                    'stroke-width': 0.75,
                    'stroke-color': 'white',
                    'fill-color': 'rgba(100,100,100,0.25)',
                  }}
                  source={
                    new VectorSource({
                      format: new GeoJSON(),
                      url: function (extent) {
                        return `${url}?service=WFS&version=${
                          layer.paramsMap['version']
                        }&request=GetFeature&typename=${layerName}&outputFormat=application/json&srsname=EPSG:3857&bbox=${extent.join(
                          ','
                        )},EPSG:3857`;
                      },
                      strategy: bboxStrategy,
                    })
                  }
                ></VectorLayer>
              );
            }
          })}
          {shapefileLayers.map((layer: ViewLayer) => {
            return (
              <ShapefileLayer
                ref={(el: any) => {
                  layerRefs.current[layer.id] = el;
                }}
                key={layer.id}
                show={layer.active}
                zIndex={1}
                layer={layer}
              ></ShapefileLayer>
            );
          })}
          {gsRasLayers.map((layer: ViewLayer) => {
            const url = layer.paramsMap['uri'] + '/wms';
            const layerName =
              (layer.paramsMap['workspace'] ? layer.paramsMap['workspace'] + ':' : '') + layer.paramsMap['layer'];
            return (
              <GsRasLayer
                ref={(el: any) => {
                  layerRefs.current[layer.id] = el;
                }}
                key={layer.id}
                layer={layer}
                show={layer.active}
                zIndex={1}
              ></GsRasLayer>
            );
          })}
          {gsVecLayers.map((layer: ViewLayer) => {
            const url = layer.paramsMap['uri'];
            const layerName =
              (layer.paramsMap['workspace'] ? layer.paramsMap['workspace'] + ':' : '') + layer.paramsMap['layer'];

            return (
              <GsVecLayer
                ref={(el: any) => {
                  layerRefs.current[layer.id] = el;
                }}
                key={layer.id}
                show={layer.active}
                layer={layer}
              ></GsVecLayer>
            );
          })}
        </Layers>
        <VCMeasurementTool
          ref={(el: any) => {
            measurementsRefs.current = el;
          }}
          show={true}
          zIndex={2}
        ></VCMeasurementTool>
        <VCDrawingTool
          ref={(el: any) => {
            drawingsRefs.current = el;
          }}
          show={true}
          zIndex={2}
        ></VCDrawingTool>
        <Controls>
          <FullScreenControl />
          <MouseTrackerControl />
          <PixelInfoControl />
          <ScaleLineControl />
        </Controls>
      </Map>
      <div
        style={{
          display: loadingMapData ? 'flex' : 'none',
          position: 'absolute',
          bottom: 0,
          right: 0,
          zIndex: 10,
          padding: '1rem',
          borderTopLeftRadius: '1rem',
          backgroundColor: 'rgba(200,200,200,0.6)',
        }}
      >
        <Loader size="small" type={'converging-spinner'} themeColor={'primary'} />
      </div>

      <div
        className=""
        style={{
          position: 'absolute',
          zIndex: '1000',
          display: 'flex',
          flexDirection: 'column',
          bottom: 20,
          left: '20px',
        }}
      >
        <div id="location"></div>
      </div>
    </div>
  );
});

OLViewer.displayName = 'OLViewer';

export default OLViewer;
