import React, { useEffect, useState } from 'react';
import { Slider, SliderLabel } from '@progress/kendo-react-inputs';
import DraggableWindow from '../../../../components/DraggableWindow';
import { BandInfo, LayerType, ViewLayer, VisualizationType, RAMP_COLORS } from '../../../../types';
import { useConsumeViewerState } from '../../../../context/viewer';
import { updateLayerParam } from '../../../../common/viewerConfigHelper';
import { getOrGenerateDefaultVisualizationParams } from '../../../../converters/viewLayerHelper';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import SinglebandControls from './SinglebandControls';
import RgbControls from './RgbControls';
import HillshadeControls from './HillshadeControls';
import VectorControls from './VectorControls';

interface OLGeotifControlsProps {
  visible: boolean;
  onClose: () => void;
  zIndex: number;
  style?: React.CSSProperties;
  onLayerUpdated?: (layer: ViewLayer) => void;
}

const OLGeotifControls: React.FC<OLGeotifControlsProps> = (props: OLGeotifControlsProps) => {
  const { selectedLayer, domViewerRef } = useConsumeViewerState();
  const [availableVisualizations, setAvailableVisualizations] = useState<VisualizationType[]>([]);
  const [currentlySelectedVisualization, setCurrentlySelectedVisualization] = useState<VisualizationType>(null);
  const [internalSelectedLayer, setInternalSelectedLayer] = useState<ViewLayer>(null);
  const [currentOpacity, setCurrentOpacity] = useState<number>(1);

  useEffect(() => {
    let newInternalLayer = { ...selectedLayer };
    if (!selectedLayer) {
      // No selectd layer
    } else if (
      selectedLayer.id === internalSelectedLayer?.id &&
      selectedLayer.lastUpdated === internalSelectedLayer.lastUpdated
    ) {
      // No new changes to consider
    } else {
      const visualizations: VisualizationType[] = [];
      const visualization = getOrGenerateDefaultVisualizationParams(selectedLayer);
      if (selectedLayer.layerType === LayerType.GeoTif || selectedLayer.layerType === LayerType.GSRAS) {
        if (visualization?.singleband) {
          visualizations.push(VisualizationType.SINGLEBAND);
          visualizations.push(VisualizationType.HILLSHADE);
        }
        if (visualization?.rgb) {
          visualizations.push(VisualizationType.RGB);
        }
      } else if (selectedLayer.layerType === LayerType.GSVEC || selectedLayer.layerType === LayerType.WFS) {
        if (visualization?.vector) {
          visualizations.push(VisualizationType.VECTOR);
        }
      }
      if (visualizations.length <= 0) {
        visualizations.push(VisualizationType.UNAVAILABLE);
      }
      selectedLayer.paramsMap['visualization'] = visualization;
      setAvailableVisualizations(visualizations);
      setCurrentlySelectedVisualization(visualization?.type);
      if (visualization && visualization['opacity'] !== null && visualization['opacity'] !== undefined) {
        setCurrentOpacity(visualization['opacity']);
      }
      if (!selectedLayer.paramsMap['visualization']) {
        const newLayer = JSON.parse(JSON.stringify(selectedLayer));
        updateLayerParam(newLayer, 'visualization', visualization);
        newInternalLayer = newLayer;
        props.onLayerUpdated(newLayer);
      }
    }
    setInternalSelectedLayer(newInternalLayer);
  }, [selectedLayer]);

  if (
    !internalSelectedLayer ||
    (internalSelectedLayer.layerType !== LayerType.GeoTif &&
      internalSelectedLayer.layerType !== LayerType.GSRAS &&
      internalSelectedLayer.layerType !== LayerType.GSVEC &&
      internalSelectedLayer.layerType !== LayerType.WFS)
  )
    return null;

  return (
    <DraggableWindow
      {...props}
      title={'Raster Symbology'}
      boundaryElement={domViewerRef?.current}
      initialPosition={{ x: 60, y: 120 }}
    >
      <div className="d-flex flex-column p-2">
        <FloatingLabel
          label={'Visualization Type'}
          editorId={'visType'}
          editorValue={true}
          className="w-75 mx-auto pb-2"
        >
          <DropDownList
            style={{
              width: '200px',
            }}
            size="small"
            data={availableVisualizations}
            value={currentlySelectedVisualization}
            onChange={(event: any) => {
              const newLayer = JSON.parse(JSON.stringify(selectedLayer));
              const vis = newLayer.paramsMap.visualization;
              vis.type = event.target.value;
              updateLayerParam(newLayer, 'visualization', vis);
              setCurrentlySelectedVisualization(event.target.value);
              setInternalSelectedLayer(newLayer);
              props.onLayerUpdated(newLayer);
            }}
            disabled={selectedLayer === null}
            popupSettings={{ width: 250 }}
          />
        </FloatingLabel>
      </div>
      <div className="d-flex flex-column p-2">
        <FloatingLabel
          label={'Opacity'}
          editorId={'opacity'}
          editorValue={currentlySelectedVisualization ? '' + currentlySelectedVisualization : ''}
          className="w-75 mx-auto pb-2"
        >
          <Slider
            min={0}
            max={1}
            step={0.01}
            value={currentOpacity}
            onChange={(e) => {
              const newLayer = JSON.parse(JSON.stringify(selectedLayer));
              const vis = newLayer.paramsMap.visualization;
              vis.opacity = e.value;
              updateLayerParam(newLayer, 'visualization', vis);
              setCurrentOpacity(e.value);
              setInternalSelectedLayer(newLayer);
              props.onLayerUpdated(newLayer);
            }}
          >
            <SliderLabel position={0}>0%</SliderLabel>
            <SliderLabel position={1}>100%</SliderLabel>
          </Slider>
        </FloatingLabel>
      </div>
      {internalSelectedLayer && currentlySelectedVisualization === VisualizationType.SINGLEBAND && (
        <SinglebandControls layer={internalSelectedLayer} onLayerUpdated={props.onLayerUpdated} />
      )}
      {internalSelectedLayer && currentlySelectedVisualization === VisualizationType.RGB && (
        <RgbControls layer={internalSelectedLayer} onLayerUpdated={props.onLayerUpdated} />
      )}
      {internalSelectedLayer && currentlySelectedVisualization === VisualizationType.HILLSHADE && (
        <HillshadeControls layer={internalSelectedLayer} onLayerUpdated={props.onLayerUpdated} />
      )}
      {internalSelectedLayer && currentlySelectedVisualization === VisualizationType.VECTOR && (
        <VectorControls layer={internalSelectedLayer} onLayerUpdated={props.onLayerUpdated} />
      )}
    </DraggableWindow>
  );
};

export default OLGeotifControls;
