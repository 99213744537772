import React, { FocusEventHandler, ForwardRefRenderFunction } from 'react';
import styled from 'styled-components';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Label, Error } from '@progress/kendo-react-labels';
import {
  NumericTextBox as KendoInput,
  NumericTextBoxChangeEvent,
  NumericTextBoxBlurEvent,
  NumericTextBoxFocusEvent,
} from '@progress/kendo-react-inputs';

interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  value?: number;
  error?: string;
  hideSpinner?: boolean;
  className?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  onChange?: (e: NumericTextBoxChangeEvent) => void;
  onBlur?: (e: NumericTextBoxFocusEvent) => void;
}

const StyledKendoInput = styled(KendoInput)`
  border: 2px solid var(--geosap-input-border-color);
  color: var(--geosap-primary-color);
  &:focus {
    color: var(--geosap-primary-color);
  }
  &::placeholder {
    color: var(--geosap-primary-color);
  }
  &::-webkit-input-placeholder {
    color: var(--geosap-primary-color);
  }
  &::-moz-placeholder {
    color: var(--geosap-primary-color);
    opacity: 1;
  }
`;

const Input: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { name, label, placeholder, value, error, hideSpinner, disabled, className, style, onChange, onBlur },
  ref
) => {
  const isValid = !error;

  return (
    <FieldWrapper className={'py-2 ' + (className ? className : '')} style={style}>
      {label && (
        <Label editorId={name} editorValid={isValid}>
          {label}
        </Label>
      )}
      <div className={'k-form-field-wrap'}>
        <StyledKendoInput
          name={name}
          id={name}
          onChange={onChange}
          onBlur={onBlur}
          valid={isValid}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          format={{ maximumFractionDigits: 10 }}
          spinners={!hideSpinner}
        />
        {!isValid && <Error>{error}</Error>}
      </div>
    </FieldWrapper>
  );
};

export default React.forwardRef(Input);
