import React, { useEffect, useState } from 'react';
import { BandInfo, ViewLayer } from '../../../../types';
import { updateLayerParam } from '../../../../common/viewerConfigHelper';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { RasterVisualization, RAMP_COLORS } from '../../../../types/Rendering';
import BandRangeSlider from './BandRangeSlider';

interface OLGeotifControlsProps {
  layer: ViewLayer;
  onLayerUpdated?: (layer: ViewLayer) => void;
}

const SinglebandControls: React.FC<OLGeotifControlsProps> = ({ layer, onLayerUpdated }) => {
  const [availableBands, setAvailableBands] = useState([]);
  const [availableColorRamps, setAvailableColorRamps] = useState([]);
  const [currentlySelectedColorRamp, setCurrentlySelectedColorRamp] = useState(RAMP_COLORS[0]);
  const [bandInfo, setBandInfo] = useState(null);

  useEffect(() => {
    if (layer) {
      const bands: BandInfo[] = layer.paramsMap['bands'];
      if (bands) {
        const visualization: RasterVisualization = layer.paramsMap['visualization'];
        const singlebandVis = visualization.singleband;
        setAvailableBands(bands);

        setAvailableColorRamps(RAMP_COLORS);
        setCurrentlySelectedColorRamp(singlebandVis.rampColor ? singlebandVis.rampColor : RAMP_COLORS[1]);

        const bandInfo: BandInfo = { index: singlebandVis.bandIndex, name: singlebandVis.bandName };

        if (singlebandVis.min !== null && singlebandVis.min !== undefined) {
          bandInfo.min = singlebandVis.min;
          if (singlebandVis.currentMin !== null && singlebandVis.currentMin !== undefined) {
            bandInfo.currentMin = singlebandVis.currentMin;
          } else {
            bandInfo.currentMin = singlebandVis.min;
          }
        }
        if (singlebandVis.max !== null && singlebandVis.max !== undefined) {
          bandInfo.max = singlebandVis.max;
          if (singlebandVis.currentMax !== null && singlebandVis.currentMax !== undefined) {
            bandInfo.currentMax = singlebandVis.currentMax;
          } else {
            bandInfo.currentMax = singlebandVis.max;
          }
        }
        setBandInfo(bandInfo);
      }
    } else if (!layer) {
      setAvailableColorRamps([]);
      setCurrentlySelectedColorRamp(null);
    }
  }, [layer]);

  const handleBandChange = (newBand: BandInfo) => {
    const vis: RasterVisualization = layer.paramsMap.visualization;
    vis.singleband.bandIndex = newBand.index;
    vis.singleband.bandName = newBand.name;
    vis.singleband.min = newBand.min;
    vis.singleband.max = newBand.max;
    updateLayerParam(layer, 'visualization', vis);
    onLayerUpdated(layer);
  };

  const handleSliderChange = (newMin: number, newMax: number) => {
    const newLayer = JSON.parse(JSON.stringify(layer));
    const vis = newLayer.paramsMap.visualization;
    let i = 0;
    for (i = 0; i < newLayer.paramsMap.bands.length; i++) {
      if (newLayer.paramsMap.bands[i].name === vis.singleband.bandName?.name) {
        const newBands = newLayer.paramsMap.bands;
        newBands[i].currentMin = newMin;
        newBands[i].currentMax = newMax;
        updateLayerParam(newLayer, 'bands', newBands);
        break;
      }
    }
    const newVis = newLayer.paramsMap.visualization;
    newVis.singleband.currentMin = newMin;
    newVis.singleband.currentMax = newMax;
    updateLayerParam(newLayer, 'visualization', newVis);
    onLayerUpdated(newLayer);
  };

  return (
    <div className="d-flex flex-column p-2 mb-4">
      <FloatingLabel
        label={'Color Ramp'}
        editorId={'colorRamp'}
        editorValue={currentlySelectedColorRamp ? '' + currentlySelectedColorRamp : ''}
        className="w-75 mx-auto pb-2"
      >
        <DropDownList
          style={{
            width: '200px',
          }}
          size="small"
          data={availableColorRamps}
          value={currentlySelectedColorRamp}
          dataItemKey="name"
          textField="name"
          onChange={(event: any) => {
            const vis = layer.paramsMap.visualization;
            vis.singleband.rampColor = event.target.value;
            updateLayerParam(layer, 'visualization', vis);
            setCurrentlySelectedColorRamp(event.target.value);
            onLayerUpdated(layer);
          }}
          disabled={layer === null}
          popupSettings={{ width: 250 }}
        />
      </FloatingLabel>
      <BandRangeSlider
        title="Band"
        bandInfo={bandInfo}
        availableBands={availableBands}
        disabled={layer === null}
        handleBandChange={handleBandChange}
        handleSliderChange={handleSliderChange}
      ></BandRangeSlider>
    </div>
  );
};

export default SinglebandControls;
