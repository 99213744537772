import React, { useEffect, useState } from 'react';
import { RangeSlider, Slider, SliderLabel } from '@progress/kendo-react-inputs';
import { BandInfo, LayerType, ViewLayer, BandType } from '../../../../types';
import { updateLayerParam } from '../../../../common/viewerConfigHelper';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import { RasterVisualization } from '../../../../types/Rendering';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import BandRangeSliderControls from './BandRangeSlider';

interface OLGeotifControlsProps {
  layer: ViewLayer;
  onLayerUpdated?: (layer: ViewLayer) => void;
}

const RgbControls: React.FC<OLGeotifControlsProps> = ({ layer, onLayerUpdated }) => {
  const [initialized, setInitialized] = useState(false);
  const [availableBands, setAvailableBands] = useState<BandInfo[]>([]);
  const [redBandInfo, setRedBandInfo] = useState(null);
  const [greenBandInfo, setGreenBandInfo] = useState(null);
  const [blueBandInfo, setBlueBandInfo] = useState(null);

  useEffect(() => {
    if (layer) {
      const bands: BandInfo[] = layer.paramsMap['bands'];
      if (!bands) {
        return;
      }
      setAvailableBands(bands);
      const visualization: RasterVisualization = layer.paramsMap['visualization'];
      const rgbVis = visualization.rgb;
      const currentRedIndex =
        rgbVis.redBandIndex !== null && rgbVis.redBandIndex !== undefined ? rgbVis.redBandIndex - 1 : 0;
      const currentGreenIndex =
        rgbVis.greenBandIndex !== null && rgbVis.greenBandIndex !== undefined ? rgbVis.greenBandIndex - 1 : 1;
      const currentBlueIndex =
        rgbVis.blueBandIndex !== null && rgbVis.blueBandIndex !== undefined ? rgbVis.blueBandIndex - 1 : 2;
      const getVisOrDefaultValue = (property: string, defaultValue: number) => {
        return rgbVis[property] !== null && rgbVis[property] !== undefined ? rgbVis[property] : defaultValue;
      };

      const redBandInfos: BandInfo = { name: bands[currentRedIndex].name, index: bands[currentRedIndex].index };
      const greenBandInfos: BandInfo = { name: bands[currentGreenIndex].name, index: bands[currentGreenIndex].index };
      const blueBandInfos: BandInfo = { name: bands[currentBlueIndex].name, index: bands[currentBlueIndex].index };
      redBandInfos.min = bands[currentRedIndex].min;
      redBandInfos.max = bands[currentRedIndex].max;
      redBandInfos.currentMin = getVisOrDefaultValue('redBandCurrentMin', bands[currentRedIndex].currentMin);
      redBandInfos.currentMax = getVisOrDefaultValue('redBandCurrentMax', bands[currentRedIndex].currentMax);
      greenBandInfos.min = bands[currentGreenIndex].min;
      greenBandInfos.max = bands[currentGreenIndex].max;
      greenBandInfos.currentMin = getVisOrDefaultValue('greenBandCurrentMin', bands[currentGreenIndex].currentMin);
      greenBandInfos.currentMax = getVisOrDefaultValue('greenBandCurrentMax', bands[currentGreenIndex].currentMax);
      blueBandInfos.min = bands[currentBlueIndex].min;
      blueBandInfos.max = bands[currentBlueIndex].max;
      blueBandInfos.currentMin = getVisOrDefaultValue('blueBandCurrentMin', bands[currentBlueIndex].currentMin);
      blueBandInfos.currentMax = getVisOrDefaultValue('blueBandCurrentMax', bands[currentBlueIndex].currentMax);
      setRedBandInfo(redBandInfos);
      setGreenBandInfo(greenBandInfos);
      setBlueBandInfo(blueBandInfos);
      setInitialized(true);
    }
  }, [layer]);

  const handleRedBandChange = (newBand: BandInfo) => {
    handleBandChange(newBand, BandType.RED);
  };
  const handleGreenBandChange = (newBand: BandInfo) => {
    handleBandChange(newBand, BandType.GREEN);
  };
  const handleBlueBandChange = (newBand: BandInfo) => {
    handleBandChange(newBand, BandType.BLUE);
  };
  const handleBandChange = (newBand: BandInfo, bandType: BandType) => {
    if (!initialized) return;
    const vis: RasterVisualization = layer.paramsMap.visualization;
    if (bandType === BandType.RED) {
      vis.rgb.redBandIndex = newBand.index;
      vis.rgb.redBandCurrentMin = newBand.min;
      vis.rgb.redBandCurrentMax = newBand.max;
    } else if (bandType === BandType.GREEN) {
      vis.rgb.greenBandIndex = newBand.index;
      vis.rgb.greenBandCurrentMin = newBand.min;
      vis.rgb.greenBandCurrentMax = newBand.max;
    } else if (bandType === BandType.BLUE) {
      vis.rgb.blueBandIndex = newBand.index;
      vis.rgb.blueBandCurrentMin = newBand.min;
      vis.rgb.blueBandCurrentMax = newBand.max;
    }
    updateLayerParam(layer, 'visualization', vis);
    onLayerUpdated(layer);
  };

  const handleRedSliderChange = (newMin: number, newMax: number) => {
    handleSliderChange(BandType.RED, newMin, newMax);
  };
  const handleGreenSliderChange = (newMin: number, newMax: number) => {
    handleSliderChange(BandType.GREEN, newMin, newMax);
  };
  const handleBlueSliderChange = (newMin: number, newMax: number) => {
    handleSliderChange(BandType.BLUE, newMin, newMax);
  };
  const handleSliderChange = (bandType: BandType, newMin: number, newMax: number) => {
    const newLayer = JSON.parse(JSON.stringify(layer));
    const vis: RasterVisualization = newLayer.paramsMap.visualization;
    const rgbVis = vis.rgb;
    if (bandType === BandType.RED) {
      rgbVis.redBandCurrentMin = newMin;
      rgbVis.redBandCurrentMax = newMax;
    } else if (bandType === BandType.GREEN) {
      rgbVis.greenBandCurrentMin = newMin;
      rgbVis.greenBandCurrentMax = newMax;
    } else if (bandType === BandType.BLUE) {
      rgbVis.blueBandCurrentMin = newMin;
      rgbVis.blueBandCurrentMax = newMax;
    }
    updateLayerParam(newLayer, 'visualization', vis);
    onLayerUpdated(newLayer);
  };

  if (!availableBands || availableBands.length === 0) return null;

  return (
    <div className="d-flex flex-column p-2">
      <BandRangeSliderControls
        title="Red Band"
        bandInfo={redBandInfo}
        availableBands={availableBands}
        disabled={layer === null}
        handleBandChange={handleRedBandChange}
        handleSliderChange={handleRedSliderChange}
      ></BandRangeSliderControls>
      <BandRangeSliderControls
        title="Green Band"
        bandInfo={greenBandInfo}
        availableBands={availableBands}
        disabled={layer === null}
        handleBandChange={handleGreenBandChange}
        handleSliderChange={handleGreenSliderChange}
      ></BandRangeSliderControls>
      <BandRangeSliderControls
        title="Blue Band"
        bandInfo={blueBandInfo}
        availableBands={availableBands}
        disabled={layer === null}
        handleBandChange={handleBlueBandChange}
        handleSliderChange={handleBlueSliderChange}
      ></BandRangeSliderControls>
    </div>
  );
};

export default RgbControls;
