import React, { useEffect, useState } from 'react';
import { RangeSlider, Slider, SliderLabel } from '@progress/kendo-react-inputs';
import DraggableWindow from '../../../../components/DraggableWindow';
import { BandInfo, BandType, LayerType, ViewLayer } from '../../../../types';
import { useConsumeViewerState } from '../../../../context/viewer';
import { updateLayerParam } from '../../../../common/viewerConfigHelper';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { RasterVisualization, RAMP_COLORS } from '../../../../types/Rendering';
import { NumericInput } from '../../../../components/form';

interface BandRangeSliderControls {
  title: string;
  bandInfo: BandInfo;
  bandType?: BandType;
  availableBands: any[];
  disabled: boolean;
  handleBandChange?: (newBand: BandInfo) => void;
  handleSliderChange?: (newMin: number, newMax: number) => void;
}

const BandRangeSliderControls: React.FC<BandRangeSliderControls> = ({
  title,
  bandInfo,
  bandType,
  disabled,
  availableBands,
  handleBandChange,
  handleSliderChange,
}) => {
  const [editingMin, setEditingMin] = useState(false);
  const [editingMax, setEditingMax] = useState(false);

  if (bandInfo === null || bandInfo === undefined) {
    return null;
  }

  return (
    <div className="d-flex flex-column">
      <FloatingLabel
        label={title}
        editorId={'visType'}
        editorValue={bandInfo.index !== null && bandInfo.index !== undefined ? '' + bandInfo.index : ''}
        className="w-75 mx-auto pb-2"
      >
        <DropDownList
          style={{
            width: '200px',
          }}
          size="small"
          data={availableBands}
          value={availableBands[bandInfo.index - 1]}
          dataItemKey="name"
          textField="name"
          onChange={(event: any) => {
            handleBandChange(event.target.value);
          }}
          disabled={disabled}
          popupSettings={{ width: 250 }}
        />
      </FloatingLabel>

      <RangeSlider
        style={{ marginLeft: '2.5rem', paddingTop: '0.8rem' }}
        step={(bandInfo.max - bandInfo.min) / 100}
        min={bandInfo.min}
        max={bandInfo.max}
        disabled={disabled}
        value={{ start: bandInfo.currentMin, end: bandInfo.currentMax }}
        onChange={(e) => {
          handleSliderChange(e.value.start, e.value.end);
        }}
      >
        {[
          { pos: bandInfo.min, text: bandInfo.min },
          { pos: bandInfo.max, text: bandInfo.max },
        ].map((label, i) => (
          <SliderLabel key={i} position={label.pos}>
            <span style={{ fontSize: '0.75rem' }}>{parseFloat(label.text.toFixed(6))}</span>
          </SliderLabel>
        ))}
      </RangeSlider>
      <div
        className="w-75 mx-auto pt-1 pb-2"
        style={{ display: 'flex', alignItems: 'center', marginTop: '1.5rem', justifyContent: 'space-between' }}
      >
        {!editingMin && (
          <span
            onClick={() => {
              setEditingMin(true);
              setEditingMax(false);
            }}
          >
            {parseFloat(bandInfo.currentMin.toFixed(6))}
          </span>
        )}
        {editingMin && (
          <NumericInput
            name={''}
            className="me-2"
            style={{ maxWidth: '50%' }}
            value={bandInfo.currentMin}
            onChange={(e) => {
              handleSliderChange(e.value, bandInfo.currentMax);
            }}
            hideSpinner={true}
            onBlur={() => {
              setEditingMin(false);
            }}
          />
        )}
        {!editingMax && (
          <span
            onClick={() => {
              setEditingMax(true);
              setEditingMin(false);
            }}
          >
            {parseFloat(bandInfo.currentMax.toFixed(6))}
          </span>
        )}
        {editingMax && (
          <NumericInput
            name={''}
            className="me-2"
            style={{ maxWidth: '50%' }}
            value={bandInfo.currentMax}
            onChange={(e) => {
              handleSliderChange(bandInfo.currentMin, e.value);
            }}
            hideSpinner={true}
            onBlur={() => {
              setEditingMax(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default BandRangeSliderControls;
